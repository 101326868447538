import React from "react"
import { PageContainer } from "../../shared/Refactor/Containers"
import Investors from "../Investors"
import Advisors from "./Advisors"
import TeamMembers from "./TeamMembers"


const Team = () => {
  return <PageContainer>
    <TeamMembers />
    <Advisors />
    <Investors />
  </PageContainer>
}

export default Team