import React from "react";
import styled from "styled-components";

import Member from "./Member";
import { TEAM } from "../../constants/team";
import { Highlight, H2Styled as Header } from "../../shared/Headers";
import { SectionMesh as Section } from "../../shared/Refactor/Sections";
import { Grid } from "../../shared/Refactor/Lists";

const Container = styled(Grid)`
  grid-gap: 2em 0;
  justify-content: center;
  @media (min-width: 500px) {
    justify-content: space-between;
    grid-template-columns: repeat(2, auto);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, auto);
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(5, auto);
  }
`;

const TeamMembers = () => {
  return (
    <Section hash="team" >
      <Header>
        Meet <br />
        The <Highlight>Team</Highlight>
      </Header>
      <Container>
        {TEAM.map((team, idx) => {
          return (
            <Member
              key={team.name}
              name={team.name}
              title={team.title}
              img={team.image}
            />
          );
        })}
      </Container>
    </Section>
  );
};

export default TeamMembers;
